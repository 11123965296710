import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { ArticleList, AuthorLandingTopper, Paginate, SEO } from 'components';
import { className, getSlugFromAuthorName, isNonEmptyArray } from 'utils';
import { ThemeContext } from 'utils/constants';
import styles from './authorLanding.module.scss';

const AuthorLanding = ({ data, location: { pathname }, pageContext }) => {
  const { numPages, currentPage, authorPath } = pageContext;
  const context = useContext(ThemeContext);
  const {
    contentfulAuthor: { name, title, description, linkText, linkUrl, image },
    articles: { edges: articleEdges },
  } = data;

  const slug = getSlugFromAuthorName(name);
  const mappedArticles = isNonEmptyArray(articleEdges) && articleEdges.map(({ node }) => node);
  const seoTitle = name && title ? `${name} - ${title}` : name;

  return (
    <div className={context}>
      <Helmet
        bodyAttributes={{
          class: 'has-animation',
        }}
      />
      <SEO title={seoTitle} pathname={pathname} shareImage={image} description={description} />
      <AuthorLandingTopper
        title={title}
        name={name}
        description={description}
        linkText={linkText}
        linkUrl={linkUrl}
        image={image}
      />
      <div className="page-left-inset page-left-inset-content body-content">
        <div className={styles.contentWrapper}>
          {mappedArticles && (
            <div className={styles.innerWrapper}>
              <ArticleList
                articles={mappedArticles}
                currentPage={currentPage}
                numPages={numPages}
                {...className(
                  styles.onArticleLanding,
                  styles.articleList,
                  numPages < 2 && 'no-pagination',
                )}
              />
              {numPages > 1 && (
                <Paginate
                  currentPage={currentPage}
                  numPages={numPages}
                  basePath={`/${authorPath}/${slug}`}
                  className={styles.pagination}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

AuthorLanding.propTypes = {
  data: PropTypes.shape({
    contentfulAuthor: PropTypes.object.isRequired,
    articles: PropTypes.object.idRequired,
    appearances: PropTypes.object,
    appearancesLanding: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  seo: PropTypes.object,
  pageContext: PropTypes.shape({
    numPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    authorPath: PropTypes.string.isRequired,
  }).isRequired,
};

export default AuthorLanding;

export const query = graphql`
  query authorLanding($skip: Int!, $limit: Int!, $id: String!) {
    contentfulAuthor(id: { eq: $id }) {
      name
      description {
        description
      }
      id
      title
      linkText
      linkUrl
      image {
        file {
          url
        }
      }
    }

    articles: allContentfulArticle(
      filter: { author: { id: { eq: $id } } }
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          author {
            name
            image {
              file {
                url
              }
            }
          }
          slug
          title
          intro {
            intro
          }
          date(formatString: "MM.DD.YY")
        }
      }
    }
    appearances: allContentfulUpcomingAppearance(
      limit: 3
      sort: { fields: date, order: DESC }
      filter: { author: { id: { eq: $id } } }
    ) {
      edges {
        node {
          title: headline
          date(formatString: "YY-MM-DD")
          slug: link
        }
      }
    }

    appearancesLanding: contentfulAppearancesLanding {
      slug
    }
  }
`;
